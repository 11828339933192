import React from 'react';

export const TitleData = [
	{
		title: '02. Projects',
		body: (
			<span>
				Dive into my recent Web Development Bootcamp capstone project, while I update my
				Happy Hour Finder app with new spots. Stay tuned for fresh deals in town!
			</span>
		),
	},
	{
		title: '03. Skills',
		body: (
			<span>
				Proficient in key web development languages and frameworks such as{' '}
				<mark>HTML, CSS, JavaScript, and React.</mark> My expertise extends to crafting
				scalable UI components with React, and I possess Back-End skills utilizing{' '}
				<mark>Axios and NodeJS.</mark> Ready to deliver practical and effective web
				solutions.
			</span>
		),
	},
	{
		title: 'VanSippy | Happy Hour Finder',
		body: (
			<span>
				Front and Back-end React development using Yelp, Mapbox.js and Axios for API
				requests. It features an attractive design with Material UI and enables users to
				search and filter nearby happy hour specials based on geolocation.
			</span>
		),
	},
];
