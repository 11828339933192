import React from 'react';
import Bubbles from '../Bubbles/Bubbles';

function SkillsPage() {
	return (
		<>
			<Bubbles />
		</>
	);
}

export default SkillsPage;
